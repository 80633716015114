import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import {
  UserListProps,
  UserListResponse,
  UserDetailProps,
  UserDetailResponse,
} from "src/models/admin-user";

export const adminUserApi = createApi({
  reducerPath: "adminUser",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      // 获取全部用户列表
      getUserList: builder.mutation<Result<UserListResponse>, UserListProps>({
        query: (UserListProps) => ({
          url: `fuzzy_search`,
          method: "POST",
          body: UserListProps,
        }),
        transformResponse: (res: Response<UserListResponse>) => new Value(res),
      }),
      // 获取用户详情
      getUserDetail: builder.mutation<
        Result<UserDetailResponse>,
        UserDetailProps
      >({
        query: (UserDetailProps) => ({
          url: `/user_multiple_profile/get_all_related_user`,
          method: "POST",
          body: UserDetailProps,
        }),
        transformResponse: (res: Response<UserDetailResponse>) =>
          new Value(res),
      }),
    };
  },
});

export const { useGetUserListMutation, useGetUserDetailMutation } =
  adminUserApi;
