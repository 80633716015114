import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import { UserPaymentInfo } from "src/models/payment";

export const walletApi = createApi({
  reducerPath: "wallet",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getPaymentHistory: builder.mutation<
        Result<any>,
        {
          pageSize?: number;
          startKey?: string;
          forwardOrder?: boolean;
          isCoin: boolean;
          onlyNotWithdraw?: boolean;
        }
      >({
        query: ({
          pageSize,
          startKey,
          forwardOrder,
          isCoin,
          onlyNotWithdraw,
        }) => ({
          url: `getpaymenthistory`,
          method: "POST",
          body: {
            pagesize: pageSize || 20,
            startkey: startKey,
            forwardorder: forwardOrder,
            is_coin: isCoin,
            only_not_withdraw: onlyNotWithdraw,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      getPaymentData: builder.mutation<Result<UserPaymentInfo>, {}>({
        query: () => ({
          url: `erc20_token`,
          method: "POST",
          body: {
            request_type: "check_balances",
          },
        }),
        transformResponse: (res: Response<UserPaymentInfo>) => new Value(res),
      }),
      cashCoinsPayment: builder.mutation<Result<UserPaymentInfo>, {
        amount: number;
      }>({
        query: ({ amount }) => ({
          url: `erc20_token`,
          method: "POST",
          body: {
            request_type: "convert_coin",
            request_data: {
              amount
            },
          },
        }),
        transformResponse: (res: Response<UserPaymentInfo>) => new Value(res),
      }),
      rewardUser: builder.mutation<
        Result<any>,
        { receiver: string; amount: string; message: string }
      >({
        query: ({ receiver, amount, message }) => ({
          url: `erc20_token`,
          method: "POST",
          body: {
            request_type: "reward_to_person",
            request_data: {
              receiver,
              amount,
              message,
            },
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      coinWithdraw: builder.mutation<
        Result<MakePayoutDataReturn>,
        {
          amount: number;
        }
      >({
        query: ({ amount }) => ({
          url: `erc20_token`,
          method: "POST",
          body: {
            request_type: "withdrawal",
            request_data: {
              amount,
            },
          },
        }),
        transformResponse: (res: Response<MakePayoutDataReturn>) =>
          new Value<MakePayoutDataReturn>(res),
      }),
      makePayout: builder.mutation<
        Result<MakePayoutDataReturn>,
        {
          paymentIds: string[];
        }
      >({
        query: ({ paymentIds }) => ({
          url: `makepayout`,
          method: "POST",
          body: {
            payment_ids: paymentIds,
          },
        }),
        transformResponse: (res: Response<MakePayoutDataReturn>) =>
          new Value<MakePayoutDataReturn>(res),
      }),
      getStripeAccountLink: builder.query<
        Result<GetStripeAccountLinkDataReturn>,
        {}
      >({
        query: () => ({
          url: `getstripeaccountlink`,
          method: "POST",
        }),
        transformResponse: (res: Response<GetStripeAccountLinkDataReturn>) =>
          new Value(res),
      }),
      getPayoutAccount: builder.query<
        Result<GetPayoutAccountDataReturn>,
        {
          returnUrl: string;
        }
      >({
        query: ({ returnUrl }) => ({
          url: `getpayoutaccount`,
          method: "POST",
          body: {
            return_url: returnUrl,
          },
        }),
        transformResponse: (res: Response<GetPayoutAccountDataReturn>) =>
          new Value<GetPayoutAccountDataReturn>(res),
      }),
    };
  },
});

interface GetStripeAccountLinkDataReturn {
  object: string;
  created: number;
  url: string;
}

interface GetPayoutAccountDataReturn {
  account_id: string;
  url: string;
}

interface MakePayoutDataReturn {
  error_payment_ids: string[];
  success_payment_ids: string[];
}

export const {
  useGetPaymentHistoryMutation,
  useGetPaymentDataMutation,
  useRewardUserMutation,
  useLazyGetPayoutAccountQuery,
  useLazyGetStripeAccountLinkQuery,
  useMakePayoutMutation,
  useCoinWithdrawMutation,
  useCashCoinsPaymentMutation,
} = walletApi;
