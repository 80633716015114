import { PostEntity } from "src/models/post";
import { MessageTypeEnum } from "src/models/post";
import { RequestStatusEnum } from "src/models/request";

export enum Source {
  com = "com",
  cn = "cn"
}

export interface UserProfileProps {
  userId: string;
  source?: Source;
  baseUrl?: string;
}

export interface GetUserMessageProps {
  messageType: MessageTypeEnum;
  userId: string;
  pageSize?: number;
  startKey?: string;
  forwardOrder?: boolean;
  type?: number;
  startTime?: number;
  endTime?: number;
  locId?: string;
  status?: RequestStatusEnum;
  pay_to_watch?: boolean;
  // 1. 不传时查全部，
  // 2. 传true, 查所有付费观看的帖子，
  // 3. 传false, 查所有免费的帖子。
  baseUrl?: string;
  source?: Source;
}

export interface CommonUser {
  followed_by: string[];
  followed_count: number;
  latest_message_timestamp: number;
  personalized_signature: string;
  user_avatar: string;
  user_id: string;
  user_info: any;
  user_name: string;
  is_friend?: boolean;
  posts_count: number;
}

export interface SimpleUser {
  user_avatar: string;
  user_id: string;
  user_name: string;
}

export interface Salt {
  client_salt: string;
  server_salt: string;
}

export interface UserProfile {
  access_token: string;
  allow_from_local_media: boolean;
  birthday: string;
  client_salt: string;
  email: string;
  followed_by: string[];
  followed_count: number;
  followed_location: number;
  following_count: number;
  friend_count: number;
  friend_status: number;
  gender: string;
  geohash: string;
  gps: string; //"34.194376, 108.876236"
  latest_message_timestamp: number; //seconds
  liked_message_count: number;
  liked_url_count: number;
  notification_setting: {
    all: boolean;
    comment: boolean;
    follow: boolean;
    like: boolean;
    request: boolean;
    response: boolean;
  };
  openid: string;
  password_hash: string;
  payout_account_id: string;
  personalized_signature: string;
  personalized_signature_cn :string;
  privilege_level: number;
  public_key: string;
  refresh_token: string;
  type: string; //"User"
  user_avatar: string;
  user_id: string;
  user_info: any;
  user_name: string;
  user_type: UserType;
  is_admin: boolean;
  user_cover: string;
  is_official: boolean;
  permissions?:string[];
  role_id?:string;
  role_name?:string;
  block_user_post?: boolean;
  block_user_chat?: boolean;
  user_name_cn?: string;
  is_subscribed_user?: boolean;
  can_subscribe?: boolean; //表示该用户可以可以被订阅;
  pay_to_watch_count?: number; // 所有付费观看的帖子总数
  sub_user_count?: number; // 子账户总数
  all_unread_count?: number; // 所有关联账号的未读数之和
  other_unread_count?: number; // 其他关联账号的未读数之和
}

export enum UserType {
  Native = 1,
  WeChat = 2,
  Facebook = 3,
  Google = 4,
  Apple = 5,
  sub_profile = 6
}

export enum RequestType {
  Responding = "responding",
  Ordered = "ordered",
  Requested = "requested",
}

export interface FootprintsRequestData {
  user_id: string;
}

export interface PostMedia {
  compressed_url: string;
  hash: string;
  height: number;
  sign: string;
  sign_time: number;
  size: number;
  thumb_from_you: boolean;
  thumbup: number;
  timestamp: number;
  type: number;
  url: string;
  width: number;
}
export interface FootprintEntity {
  latest_post_id: string;
  latest_post_time: number;
  loc_id: string;
  loc_name: string;
  post_media: PostMedia[];
  timestamp: number;
  user_id: string;
}

export interface TimePostEntity {
  messages: PostEntity[];
  startkey: string;
  time: number;
  time_prefix_month?: number;
  time_prefix_year?: number;
}

export interface TimePostProps {
  type: number;
  timezone: string;
  user_id: string;
  pagesize: number;
  year?: number;
  month?: number;
}


/**
 * block user post chat
 */

export enum Operation {
  Delete = "delete",
  Add = "add",
}

export enum Type {
  Chat = "chat",
  Post = "post",
}
export interface BlockRequest {
  description: string;
  end_time: string;
  operation: Operation;
  type: Type;
  user_id: string;
  apply_to_all_profiles: boolean;
  [property: string]: any;
}
export interface CancelBlockRequest {
  operation: Operation;
  type: Type;
  user_id: string;
  apply_to_all_profiles: boolean;
  [property: string]: any;
}