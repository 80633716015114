import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import {
  CreateUserProps,
  CreateUserResponse,
  GetUserListProps,
  GetSubUserListResponse,
  SwitchUssrProps,
} from "src/models/multi-user";

export const multiUserManagementApi = createApi({
  reducerPath: "multiUserManagement_api",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => {
    return {
      // 创建子用户
      createSubUser: builder.mutation<
        Result<CreateUserResponse>,
        CreateUserProps
      >({
        query: (CreateUserProps) => ({
          url: `/user_multiple_profile/create_another_user`,
          method: "POST",
          body: CreateUserProps,
        }),
        transformResponse: (res: Response<CreateUserResponse>) =>
          new Value(res),
      }),

      // 获取子账号列表
      getSubUserList: builder.mutation<
        Result<GetSubUserListResponse>,
        GetUserListProps
      >({
        query: (GetUserListProps) => ({
          url: `/user_multiple_profile/get_all_related_user`,
          method: "POST",
          // body: GetUserListProps,
        }),
        transformResponse: (res: Response<GetSubUserListResponse>) =>
          new Value(res),
      }),

      // 切换主账号
      switchMainAccount: builder.mutation<
        Result<CreateUserResponse>,
        SwitchUssrProps
      >({
        query: (SwitchUssrProps) => ({
          url: `/user_multiple_profile/switch_user`,
          method: "POST",
          body: SwitchUssrProps,
        }),
        transformResponse: (res: Response<CreateUserResponse>) =>
          new Value(res),
      }),
    };
  },
});

export const {
  useCreateSubUserMutation,
  useGetSubUserListMutation,
  useSwitchMainAccountMutation,
} = multiUserManagementApi;
