import { Box, Button, MenuList, MenuItem, ListItemIcon, ListItemText, Divider, Dialog, DialogTitle, DialogContent, IconButton, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { FC, useEffect, useState, useRef, useMemo } from "react";
import { paths } from "src/app-config";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import IcoLogo from "src/images/header/ico-logo-square-36_36.svg";
import IcoFollowing from "src/images/header/ico-following.svg";
import IcoMe from "src/images/header/ico-me.svg";
import IcoSwitch from "src/images/header/ico-switch.svg";
import IcoLanguage from "src/images/header/ico-language.svg";
import IcoAbout from "src/images/main/home/ico-about.svg";
import { useSelector } from 'src/tools/toolkit/store';
import { useDispatch } from 'react-redux';
import { clearIdentity, setIdentity } from '../../service/redux/app-slice';
import { clearRefreshToken, clearToken } from '../../service/redux/access-slice';
import { clearHistory } from "src/tools/utils/search-history";
import { appConfig } from 'src/app-config';
import PhotoModeList from "src/layout/header/PhotoModeList";
import { Locale, defaultLanguages } from 'src/models/locale';
import { setLanguage } from "src/service/redux/app-slice";
import IcoHistory from "src/images/post/ico-history.svg";

const CollapsedMenuList = ({ closeDrawer }) => {
  const { t, i18n } = useTranslation();
  const { credential } = useSelector(state => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [switchViewDialogOpen, setSwitchViewDialogOpen] = useState(false);
  const [switchLanguageDialogOpen, setSwitchLanguageDialogOpen] = useState(false);

  const handleClickFollowing = () => {
    closeDrawer(false);
    if (credential) {
      navigate(`${appConfig.paths.following}`);
    } else {
      navigate(appConfig.paths.login);
    }
  }

  const handleClickProfile = () => {
    closeDrawer(false);
    if (credential) {
      navigate(`${appConfig.paths.user}/${credential?.user_id}`);
    } else {
      navigate(appConfig.paths.login);
    }
  }

  const handleClickHistory = () => {
    closeDrawer(false);
    navigate(appConfig.paths.history);
  }

  const handleSignOut = () => {
    dispatch(setIdentity(undefined));
    dispatch(clearIdentity());
    dispatch(clearToken());
    dispatch(clearRefreshToken());
    clearHistory();
    localStorage.setItem('isLoggedOut', String(Date.now()));
  }

  const handleLogin = () => {
    if (credential) {
      handleSignOut();
      navigate(appConfig.paths.root);
    } else {
      navigate(appConfig.paths.login);
    }
    closeDrawer(false);
  }

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'isLoggedOut') {
        handleSignOut();
      }
      if (event.key === 'isLoggedIn') {
        window.location.reload();
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [])

  return (
    <>
      <Box
        sx={{
          height: '100%',
          padding: '20px',
          display: 'flex',
          flexDirection: "column",
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '28px'
            }}
            onClick={() => {
              closeDrawer(false);
              navigate(paths.root);
            }}
          >
            <img src={IcoLogo} width={36} height={36} />
            {t("app.title")}
          </Box>

          <MenuList
            sx={{
              '& .MuiMenuItem-root': {
                paddingLeft: '0',
              },
              "& .MuiListItemText-primary": {
                fontSize: '14px',
                fontWeight: '400',
                fontFamily: "Helvetica Neue",
              }
            }}
          >
            <MenuItem onClick={handleClickFollowing}>
              <ListItemIcon>
                <img src={IcoFollowing} width={18} height={16} />
              </ListItemIcon>
              <ListItemText>{t("app.home.following")}</ListItemText>
            </MenuItem>
            {/* !todo 添加旅行 */}
            <MenuItem onClick={handleClickProfile}>
              <ListItemIcon>
                <img src={IcoMe} width={16} height={18} />
              </ListItemIcon>
              <ListItemText>{t("app.header.mobile.profile")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClickHistory}>
              <ListItemIcon>
                <img src={IcoHistory} width={16} height={18} />
              </ListItemIcon>
              <ListItemText>{t("app.history.history")}</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => {
              setSwitchViewDialogOpen(true);
            }}>
              <ListItemIcon>
                <img src={IcoSwitch} width={18} height={18} />
              </ListItemIcon>
              <ListItemText>{t("app.header.switch")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => {
              setSwitchLanguageDialogOpen(true);
            }}>
              <ListItemIcon>
                <img src={IcoLanguage} width={18} height={18} />
              </ListItemIcon>
              <ListItemText>{t("app.header.language")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => navigate(paths.aboutUs)}>
              <ListItemIcon>
                <img src={IcoAbout} width={18} height={18} />
              </ListItemIcon>
              <ListItemText>{t("app.header.about")}</ListItemText>
            </MenuItem>

          </MenuList>

        </Box>

        <Button
          variant="contained"
          sx={{
            background: "linear-gradient(90deg,#60D6FF 0%,#3A92F9 100%)",
            borderRadius: '24px',
            height: '44px',
            width: '185px',
            textTransform: 'none',
            boxShadow: 'none',
          }}
          onClick={() => handleLogin()}
        >{credential ? t("app.login.login_out") : t("app.login.login")}</Button>
      </Box>

      {/* 切换post mode的dialog */}
      {
        switchViewDialogOpen &&
        <Dialog
          sx={{ '& .MuiDialog-paper': { width: '320px', height: '402px', borderRadius: '12px' } }}
          onClose={() => setSwitchViewDialogOpen(false)}
          aria-labelledby="switch-view"
          open={switchViewDialogOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            {t("app.header.mobile.switch-view")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setSwitchViewDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <PhotoModeList closeDialog={() => {
              setSwitchViewDialogOpen(false);
              closeDrawer(false);
            }} />
          </DialogContent>
        </Dialog>
      }

      {/* 切换language的dialog */}
      {
        switchLanguageDialogOpen &&
        <Dialog
          sx={{ '& .MuiDialog-paper': { width: '320px', height: '188px', borderRadius: '12px' } }}
          onClose={() => setSwitchLanguageDialogOpen(false)}
          aria-labelledby="switch-language"
          open={switchLanguageDialogOpen}
        >
          <DialogTitle sx={{ padding: '17px 20px' }}>
            {t("app.header.mobile.switch-language")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setSwitchLanguageDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers sx={{ padding: '16px 20px' }}>
            <RadioGroup
              defaultValue={i18n.language}
              name="radio-buttons-group"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const val = (e.target as HTMLInputElement).value;
                i18n.changeLanguage(val);
                dispatch(setLanguage(val));
                localStorage.setItem('language', val);
                setSwitchLanguageDialogOpen(false);
                closeDrawer(false);
              }}
            >
              {defaultLanguages.map((language: Locale) => (
                <FormControlLabel
                  sx={{
                    justifyContent: 'space-between',
                    marginLeft: '0'
                  }}
                  labelPlacement="start"
                  value={language.lang}
                  control={<Radio />}
                  label={`${language.icon} ${language.label}`}
                />
              ))}
            </RadioGroup>
          </DialogContent>
        </Dialog>
      }
    </>

  )
}

export default CollapsedMenuList;