import { mobileWhiteSet } from "src/app-config";
import UAParser from "ua-parser-js";
import { isbot } from "./isbot";
import { FilterXSS } from "xss";
import { openApp, ParamsObj } from "src/layout/openApp/openApp";
import { appConfig } from "src/app-config";
import { isJSON } from "src/tools/utils/stringUtil"
import { UserType } from "src/models/user"
const parser = new UAParser().getResult();

export const isBaiduBoxApp = parser.browser.name === 'baiduboxapp';

export const isWeixinBrowser = /MicroMessenger/i.test(navigator.userAgent);

export const INPUT_COMMENT_MAX_LENGTH = 1000;
export const INPUT_DESC_MAX_LENGTH = 2000;

export const WEBSITE_PRODUCTION_CN_DEV = "www-test.dev.anylocal.cn";
export const WEBSITE_PRODUCTION_CN_STAGING = "www-test.anylocal.cn";
export const WEBSITE_PRODUCTION_CN = "anylocal.cn";
export const WEBSITE_PRODUCTION_CN_SHORT = "www.anylocal.cn";
export const WEBSITE_PRODUCTION_COM = "www.anylocal.com";

export const isCN =
  window.location.host === WEBSITE_PRODUCTION_CN_SHORT ||
  window.location.host === WEBSITE_PRODUCTION_CN ||
  window.location.host === WEBSITE_PRODUCTION_CN_STAGING ||
  window.location.host === WEBSITE_PRODUCTION_CN_DEV ||
  window.location.host === "localhost:4000" ||
  window.location.host === "192.168.1.76:4000";

export const isProd = process.env.REACT_APP_ENV?.includes("prod");

export const isChrome = navigator.userAgent.indexOf("Chrome") > -1;

export const isIOS = parser.os.name === "iOS";
export const isAndroid = parser.os.name === "Android";

// 只有.cn域名在微信备案了，才能在微信里打开Anylocal app, 因此.com要转为.cn，用iframe加载com的内容
export const getWeChatValidUrl = (url: string) => {
  const handlingUrl = new URL(url);
  if (handlingUrl.host === WEBSITE_PRODUCTION_COM) {
    return url.replace(".com", ".cn").concat("&global=1");
  } else {
    return url;
  }
};

export function checkBot(): boolean {
  return isbot(navigator.userAgent);
}

export function onTableOrMobile(): boolean {
  const parser = new UAParser().getResult();
  const devices = new Set<string>(["tablet", "mobile"]);
  if (parser.device.type && devices.has(parser.device.type)) {
    return true;
  }
  return false;
}

export const isOnTableOrMobile = onTableOrMobile();

export function onMobile(): boolean {
  const parser = new UAParser().getResult();
  const devices = new Set<string>(["mobile"]);
  if (parser.device.type && devices.has(parser.device.type)) {
    return true;
  }
  return false;
}

export const isOnMobile = onMobile();

/**移动端横屏 */
export function isMobileAndLandscape(): boolean {
  const isOnMobile = onMobile();
  const isLandscape = window.innerWidth > window.innerHeight;
  return isOnMobile && isLandscape;
}

export function mobileRedirect(): boolean {
  if (checkBot()) {
    return false;
  }
  const isOnMobile = onMobile();
  if (isOnMobile) {
    if (!mobileWhiteSet.has(window.location.pathname)) {
      return true;
    }
  }
  return false;
}

export function showChatBot(): boolean {
  const isOnTableOrMobile = onTableOrMobile();
  if (isOnTableOrMobile) {
    return false;
  }
  return true;
}

export interface CountryItem {
  country_id: number;
  country_code: number;
  country_name_en: string;
  country_name_cn: string;
  ab: string;
}

export const getCountriesJson = async (): Promise<CountryItem[]> => {
  return new Promise((resolve) => {
    fetch(`${process.env.REACT_APP_WEBSITE_URL}/country.json`, {
      cache: "no-cache",
    })
      .then((response) => resolve(response.json()))
      .catch((error) => {
        console.error(error);
      });
  });
};

export interface ReportCategoryItem {
  category_id: number;
  name: string;
  cn_name: string;
}

export const getReportCategoryJson = async (): Promise<
  ReportCategoryItem[]
> => {
  return new Promise((resolve) => {
    fetch(`${process.env.REACT_APP_WEBSITE_URL}/report_category.json`, {
      cache: "no-cache",
    })
      .then((response) => resolve(response.json()))
      .catch((error) => {
        console.error(error);
      });
  });
};

export function requestFullscreen(element: Element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if ((element as any).webkitRequestFullscreen) {
    (element as any).webkitRequestFullscreen();
  } else if ((element as any).mozRequestFullScreen) {
    (element as any).mozRequestFullScreen();
  } else if ((element as any).msRequestFullscreen) {
    (element as any).msRequestFullscreen();
  } else if ((element as any).webkitEnterFullscreen) {
    (element as any).webkitEnterFullscreen();
  } else {
    console.error("Fullscreen API is not supported");
  }
}

export function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document as any).webkitExitFullscreen) {
    (document as any).webkitExitFullscreen();
  } else if ((document as any).mozCancelFullScreen) {
    (document as any).mozCancelFullScreen();
  } else if ((document as any).msExitFullscreen) {
    (document as any).msExitFullscreen();
  } else {
    console.error("Fullscreen API is not supported");
  }
}

export function isFullScreen() {
  return (
    (document.fullscreenElement && document.fullscreenElement !== null) ||
    ((document as any).webkitFullscreenElement &&
      (document as any).webkitFullscreenElement !== null) ||
    ((document as any).mozFullScreenElement &&
      (document as any).mozFullScreenElement !== null) ||
    ((document as any).msFullscreenElement &&
      (document as any).msFullscreenElement !== null)
  );
}

export function isElementFullScreen(element: Element) {
  const isFullScreen =
    document.fullscreenElement === element ||
    (document as any).webkitFullscreenElement === element ||
    (document as any).mozFullScreenElement === element ||
    (document as any).msFullscreenElement === element;
  return isFullScreen;
}

/** topic白名单配置 */
const options = {
  whiteList: {
    a: ["href", "target", "style"],
  },
  stripIgnoreTag: true,
  stripIgnoreTagBody: ["script"],
};

const myxss = new FilterXSS(options);

export const filterTopicsThroughXss = (html) => {
  return myxss.process(html);
};

export const redirectToApp = () => {
  openApp(true, {});
}

export const redirectToAppWithPostID = (id: string | undefined) => {
  if (id) {
    openApp(true, { message_id: id });
  } else {
    redirectToApp()
  }
}

export const redirectToAppWithUserId = (id: string | undefined) => {
  if (id) {
    openApp(true, { user_id: id });
  } else {
    redirectToApp()
  }
}

export const redirectToAppWithRequestID = (id: string | undefined) => {
  if (id) {
    openApp(true, { request_id: id });
  } else {
    redirectToApp()
  }
}

export const redirectToAppWithRoomID = (id: string | undefined) => {
  if (id) {
    openApp(true, { room_id: id });
  } else {
    redirectToApp()
  }
}

export const WX_APP_OPEN_ID = "wxe65ec5ced00a0650"; // 微信开放平台的app id

export const computeRedirectURL = (paramsObj: ParamsObj) => {
  // User Profile Page:
  // https://www-test.dev.anylocal.cn/shared-user?user_id=1988-5&global=0
  // Post Detail Page: 
  // https://www-test.dev.anylocal.cn/details?message_id=wqj6t9h8s-1988-5-1717147225&message_type=1&global=0
  // Request Detail Page:
  // https://www-test.dev.anylocal.cn/request-detail?request_id=wqj6t8urk-spqtest-1717490001&global=0
  // Location Page:
  // https://www-test.dev.anylocal.cn/shared-location?loc_id=wqj6t&loc_name=6ZSm5Lia6LevMTLlj7csIOmbgeWhlOWMuiwg6KW_5a6J5biCLCDpmZXopb_nnIEsIOS4reWbvQ&global=0
  // chatroom list:
  // https://www-test.dev.anylocal.cn/chatroom-list?type=all&gps=29.875419559548988%2C121.55316116203177&message_type=12
  // https://www-test.dev.anylocal.cn/chatroom-list?keyword=shanghai&type=province&gps=31.223%2C121.487&message_type=12

  // chatroom
  // https://www-test.dev.anylocal.cn/chatroom?room_id=city_room_宁波市&message_type=12&global=0
  let formatSearchParams = '';
  let pathname = '';
  const params = new URLSearchParams(window.location.search);
  const global = params.get('global');
  const isGlobalSource = window.location.host.endsWith("cn") && global === "1" ? 1 : 0;

  if (paramsObj.message_id) {
    pathname = appConfig.paths.details;
    formatSearchParams = `?message_id=${paramsObj.message_id}&message_type=1&global=${isGlobalSource}`;
  } else if (paramsObj.user_id) {
    pathname = appConfig.paths.sharedUser;
    formatSearchParams = `?user_id=${paramsObj.user_id}&global=${isGlobalSource}`;
  } else if (paramsObj.request_id) {
    pathname = appConfig.paths.requestDetail;
    formatSearchParams = `?request_id=${paramsObj.request_id}&global=${isGlobalSource}`;
  } else if (paramsObj.room_id) {
    pathname = appConfig.paths.chatroom;
    formatSearchParams = `?room_id=${encodeURI(paramsObj.room_id)}&message_type=12&global=${isGlobalSource}`;
  }
  return `${window.location.protocol}//${window.location.host}${pathname}${formatSearchParams}`;
}


export const isAnylocalApp = () => {
  const userAgent = navigator.userAgent;
  if (/Anylocal/i.test(userAgent)) {
    return true;
  }
  return false;
};

export const isValidJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

// 检查是否跨区了
export const isCrossRegion = (user_id: string) => {
  return (isCN && user_id.endsWith('_com')) || (!isCN && user_id.endsWith('_cn'))
};

export const removeSuffix = (str: string) => {
  const suffixes = ['_com', '_cn'];
  for (const suffix of suffixes) {
    if (str.endsWith(suffix)) {
      return str.slice(0, -suffix.length);
    }
  }
  return str;
}

export const foreignUserId = (userId: string) => {
  if (isCrossRegion(userId)) {
    return removeSuffix(userId);
  } else {
    return userId;
  }
};

export const isMainUser = (): boolean => {
  const userProfileJson = localStorage.getItem('app-state') ?? "";
  if (userProfileJson && isJSON(userProfileJson)) {
    const userProfile = JSON.parse(userProfileJson)?.credential;
    const userType = userProfile?.user_type;
    return userType !== UserType.sub_profile;
  } else {
    return false;
  }
}


export const findArrayDifferences = <T>(arrayA: T[], arrayB: T[]): [
  T[],
  T[]
] => {
  // 使用集合操作来比较差异
  const setA = new Set(arrayA);
  const setB = new Set(arrayB);

  // 找出 A 中有而 B 中没有的值
  const onlyInA = arrayA.filter(item => !setB.has(item));

  // 找出 B 中有而 A 中没有的值
  const onlyInB = arrayB.filter(item => !setA.has(item));

  return [onlyInA, onlyInB];
}