
// define all routing in project
export const paths = {
  default: '*',
  root: '/',
  aboutUs: '/about-us',
  following: '/following',
  worldview: '/worldview',
  worldviewViewMore: '/worldview/view-more',
  worldviewSub: '/worldview/sub',
  worldviewSearch: '/worldview/search',
  login: '/login',
  signin: '/signin',
  signup: '/signup',
  openApp: '/open-app',
  details: '/details',
  webPostDetail: '/post-detail',
  webLiveDetail: '/live-detail',
  requestDetail: '/request-detail',
  location: '/location',
  locationArea: '/location-area',
  sharedLocationArea: '/shared-location-area',
  topic: '/topic',
  autoTopic: '/auto-topic',
  sharedLocation: '/shared-location',
  user: '/user',
  aiProfile: '/ai-profile',
  member: '/member',
  history: '/history',
  recommend: '/recommend',
  sharedUser: '/shared-user',
  recommendedLocations: '/recommended-locations',
  mostVisitedLocations: '/most-visited-locations',
  recommendedPeople: '/recommended-people',
  sendRequest: '/send-request',
  paymentSuccess: '/payment-success',
  support: '/support',
  deleteAccount: '/support/deleteAccount',
  settings: '/settings',
  deleteAccountFromSettings: '/settings/deleteAccount',
  feedbackFromSettings: '/settings/feedback',
  search: '/search',
  orders: '/orders/*',
  map: '/map',
  mapMobile: '/map-mobile',
  wallet: '/wallet',
  recharge: '/recharge',
  adminPage: "/admin-page",
  sendPost: '/admin-page/send-post',
  adminPosts: '/admin-page/posts',
  adminRequest: '/admin-page/request',
  adminNews: '/admin-page/news',
  dashBoard: '/admin-page/dash-board',
  adminCreateNews: '/admin-page/news/create',
  adminEditNews: '/admin-page/news/edit',
  adminNewsDetail: '/admin-page/news/detail',
  adminTravel: '/admin-page/travel',
  newsDetail: '/news-detail',
  sharedNewsDetail: '/shared-news-detail',
  adminReports: '/admin-page/reports',
  adminChatRoom: '/admin-page/chatroom',
  adminAIKnowledge: '/admin-page/ai-knowledge',
  adminFeedback: '/admin-page/feedback',
  adminUser: '/admin-page/user',
  withdrawalReport: '/admin-page/withdrawal-report',
  reportedPosts: '/admin-page/reports/posts',
  reportedUsers: '/admin-page/reports/users',
  reportedComments: '/admin-page/reports/comments',
  adminWithdraw: '/admin-page/withdraw',
  withdrawRecords: '/admin-page/withdraw/withdraw-records',
  adminWithdrawReport: '/admin-page/withdraw/withdraw-report',
  adminWithdrawPage: '/admin-page/withdraw/adminWithdraw-page',
  adminWithdrawDetail: '/admin-page/withdraw/withdraw-detail',
  adminAppWithdraw: '/admin-app-withdraw',
  AdminManagement: '/admin-page/admin-management',
  adminList: '/admin-page/admin-management/adminList',
  promotion: '/admin-page/promotion',
  keyword: '/admin-page/promotion/keyword',
  access: '/admin-page/promotion/access',
  action: '/admin-page/promotion/action',
  sessionList: '/admin-page/promotion/session',
  ipList: '/admin-page/promotion/ip',
  adminRoleList: '/admin-page/admin-management/role-list',
  markers: '/market/*',
  market: '/market',
  items: '/market/sold-items',
  hub: '/market/request-hub',
  forSale: '/market/for-sale',
  appWallet: '/app-wallet',
  requestPurchasePost: '/request-purchase-post',
  interestQuestions: '/interest-questions',
  collection: '/collection',
  sharedCollection: '/shared-collection',
  androidTest: '/android',
  searchHistory: '/search-history',
  sharedSearch: '/shared-search',
  chatroomList: '/chatroom-list',
  chatroom: '/chatroom',
  privateRoom: '/private-room',
  chatroomVisit: '/chatroom-visit',
  chatroomJoin: '/chatroom-join',
  aiPostList: '/ai-post-list',
  wechatLoginCallback: '/wechat-login-callback',
  travel: "/travel"
}

export enum FollowTypeEnum {
  FollowingPeople = 'following-people',
  FollowingLocations = 'following-locations',
  Followers = 'followers',
  Friends = 'friends',
}

export const buildFollowPageUrl = (followType: FollowTypeEnum, userId: string): string => {
  return paths.user + `/${followType}?id=` + userId;
}


export class appConfig {
  // all paths
  static get paths() {
    return paths
  }
}


export const mobileWhiteSet = new Set<string>([
  paths.root,
  // paths.following,
  paths.worldview,
  paths.market,
  paths.aboutUs,
  paths.webPostDetail,
  paths.webLiveDetail,
  paths.requestDetail,
  paths.details,
  paths.location,
  paths.sharedLocation,
  paths.sharedUser,
  paths.map,
  paths.mapMobile,
  paths.sharedNewsDetail,
  // paths.openApp,
  paths.user,
  paths.interestQuestions,
  paths.collection,
  paths.sharedCollection,
  paths.requestDetail,
  paths.appWallet,
  paths.adminAppWithdraw,
  paths.sharedLocationArea,
  paths.adminPage
]);

export const hideOpenAppSet = new Set<string>([
  paths.sharedNewsDetail,
])