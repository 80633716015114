import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import { PictureEntity, MessageEntity, RelevantMessageEntity, PrivateRoom, PrivateRoomApplyStatus } from "src/models/chatroom";
import { MessageListProps } from "src/models/admin-chatroom";
import { PostEntity } from "src/models/post";

export const chatroomApi = createApi({
  reducerPath: "chatroom",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getRoomByNation: builder.mutation<Result<any>, { nation?: string, startKey: string, pageSize: number, gps: string, type: string }>({
        query: ({
          nation,
          startKey,
          pageSize,
          gps,
          type
        }) => ({
          url: `loc_chat/get_room_by_nation`,
          method: "POST",
          body: {
            nation,
            page_size: pageSize || 20,
            start_key: startKey,
            gps,
            type
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getHistoryMessageByRoomId: builder.mutation<Result<MessageListProps>, { room_id: string, startKey: string, pageSize: number, forward: boolean }>({
        query: ({
          room_id,
          startKey,
          pageSize,
          forward
        }) => ({
          url: `loc_chat/room_message`,
          method: "POST",
          body: {
            room_id,
            page_size: pageSize || 20,
            start_key: startKey,
            forward
          },
        }),
        transformResponse: (res: Response<MessageListProps>) => new Value(res),
      }),
      getUsersByRoomId: builder.mutation<Result<any>, { room_id: string, startKey: string, pageSize: number }>({
        query: ({
          room_id,
          startKey,
          pageSize
        }) => ({
          url: `loc_chat/room_user`,
          method: "POST",
          body: {
            room_id,
            page_size: pageSize || 20,
            start_key: startKey,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      searchRoomByText: builder.mutation<Result<any>, { search_text: string, startKey?: string, pageSize?: number, includePrivate?: boolean }>({
        query: ({
          search_text,
          startKey,
          pageSize,
          includePrivate = false
        }) => ({
          url: `loc_chat/search_room`,
          method: "POST",
          body: {
            search_text,
            page_size: pageSize || 20,
            start_key: startKey,
            include_private_room: includePrivate
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      deleteMessage: builder.mutation<Result<any>, { id: string }>({
        query: ({
          id,
        }) => ({
          url: `loc_chat/delete_message`,
          method: "POST",
          body: {
            message_id: id,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getRoomByLocId: builder.mutation<Result<any>, { gps?: string, loc_id?: string }>({
        query: ({
          gps,
          loc_id,
        }) => ({
          url: `loc_chat/get_room_by_loc`,
          method: "POST",
          body: {
            gps,
            loc_id
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      followRoom: builder.mutation<Result<any>, { id: string, follow: boolean }>({
        query: ({
          id,
          follow
        }) => ({
          url: `loc_chat/followed_room`,
          method: "POST",
          body: {
            room_id: id,
            follow
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      setMessageRead: builder.mutation<Result<any>, { id: string }>({
        query: ({
          id
        }) => ({
          url: `loc_chat/read_message`,
          method: "POST",
          body: {
            message_id: id,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      setDialogMessageRead: builder.mutation<Result<any>, { id: string }>({
        query: ({
          id
        }) => ({
          url: `loc_chat/read_message`,
          method: "POST",
          body: {
            dialog_id: id
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getFollowedRoom: builder.mutation<Result<any>, { gps: string }>({
        query: ({
          gps,
        }) => ({
          url: `loc_chat/get_followed_room`,
          method: "POST",
          body: {
            gps
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      updateRoomCover: builder.mutation<
        Result<undefined>,
        {
          room_id: string;
          cover: string;
        }
      >({
        query: ({ room_id, cover }) => ({
          url: `loc_chat/update_room_cover`,
          method: "POST",
          body: {
            room_id,
            cover,
          },
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      getPostPicture: builder.mutation<
        Result<PictureEntity>,
        {
          room_id: string
        }
      >({
        query: ({ room_id }) => ({
          url: `loc_chat/list_room_cover`,
          method: "POST",
          body: {
            room_id
          },
        }),
        transformResponse: (res: Response<PictureEntity>) => new Value(res),
      }),
      getPostListByRoomMessageId: builder.mutation<Result<any>, { id: string }>({
        query: ({
          id
        }) => ({
          url: `loc_chat/list_post_by_room_message`,
          method: "POST",
          body: {
            room_message_id: id,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getContributorsByRoomId: builder.mutation<Result<any>, { id: string, startKey?: string, pageSize?: number }>({
        query: ({
          id,
          startKey,
          pageSize
        }) => ({
          url: `loc_chat/room_submitter`,
          method: "POST",
          body: {
            room_id: id,
            page_size: pageSize || 20,
            start_key: startKey,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      searchMessage: builder.mutation<
        Result<MessageEntity>,
        {
          search_text: string,
          startKey?: string,
          pageSize?: number,
          room_id: string,
          type: string
        }
      >({
        query: ({
          room_id,
          search_text,
          startKey,
          pageSize,
          type
        }) => ({
          url: `loc_chat/room_message`,
          method: "POST",
          body: {
            room_id,
            search_text,
            page_size: pageSize || 20,
            start_key: startKey,
            type
          },
        }),
        transformResponse: (res: Response<MessageEntity>) => new Value(res),
      }),
      getRelevantMessage: builder.mutation<
        Result<RelevantMessageEntity>,
        {
          room_id: string,
          message_id: string,
          page_size: number,
          type: string,
        }
      >({
        query: ({
          room_id,
          message_id,
          page_size,
          type,
        }) => ({
          url: `loc_chat/room_message`,
          method: "POST",
          body: {
            room_id,
            message_id,
            page_size,
            type
          },
        }),
        transformResponse: (res: Response<RelevantMessageEntity>) => new Value(res),
      }),
      uploadVisitorInfo: builder.mutation<
        Result<any>,
        {
          user_id: string,
          user_name: string,
          clientDeviceId: string
        }
      >({
        query: ({
          user_id,
          user_name,
          clientDeviceId
        }) => ({
          url: `loc_chat/update_visitor_info`,
          method: "POST",
          headers: {
            "Client-Device-Id": clientDeviceId
          },
          body: {
            user_id,
            user_name
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getPrivateRoom: builder.mutation<
        Result<GetPrivateRoomResult>,
        {
          roomId: string,
        }
      >({
        query: ({
          roomId,
        }) => ({
          url: 'loc_chat/get_private_room_by_id',
          method: "POST",
          body: {
            room_id: roomId
          },
        }),
        transformResponse: (res: Response<GetPrivateRoomResult>) => new Value(res),
      }),
      checkUserInRoom: builder.mutation<
        Result<CheckUserInRoomResult>,
        {
          roomId: string,
        }
      >({
        query: ({
          roomId,
        }) => ({
          url: 'loc_chat/check_user_in_room',
          method: "POST",
          body: {
            room_id: roomId
          },
        }),
        transformResponse: (res: Response<CheckUserInRoomResult>) => new Value(res),
      }),
      applyJoinRoom: builder.mutation<
        Result<{}>,
        {
          roomId: string,
          description: string,
        }
      >({
        query: ({
          roomId,
          description
        }) => ({
          url: 'loc_chat/apply_join_room',
          method: "POST",
          body: {
            room_id: roomId,
            description
          },
        }),
        transformResponse: (res: Response<{}>) => new Value(res),
      }),
      checkApplyStatus: builder.mutation<
        Result<PrivateRoomApplyStatus>,
        {
          roomId?: string,
          applyId?: string,
        }
      >({
        query: ({
          roomId,
          applyId
        }) => ({
          url: 'loc_chat/check_apply_status',
          method: "POST",
          body: {
            room_id: roomId,
            apply_id: applyId
          },
        }),
        transformResponse: (res: Response<PrivateRoomApplyStatus>) => new Value(res),
      }),
      getJoinedRoom: builder.mutation<
        Result<GetJoinedRoomResult>, {
          pageSize: number,
          startKey: string,
        }
      >({
        query: ({ pageSize, startKey }) => ({
          url: 'loc_chat/get_joined_room_by_user',
          method: "POST",
          body: {
            page_size: pageSize,
            start_key: startKey
          }
        }),
        transformResponse: (res: Response<GetJoinedRoomResult>) => new Value(res),
      }),
      createPrivateChatroom: builder.mutation<
        Result<CreatePrivateChatroomResult>, {
          name: string,
          description: string,
          members: string[],
          roomCover: string,
          applyConfirmation: boolean,
        }
      >({
        query: ({ name, description, members, roomCover, applyConfirmation }) => ({
          url: 'loc_chat/create_private_room',
          method: "POST",
          body: {
            name,
            description,
            members,
            room_cover: roomCover,
            apply_confirmation: applyConfirmation
          }
        }),
        transformResponse: (res: Response<CreatePrivateChatroomResult>) => new Value(res),
      }),
      removeUser: builder.mutation<
        Result<any>, {
          roomId: string,
          userIds: string[]
        }
      >({
        query: ({ roomId, userIds }) => ({
          url: 'loc_chat/remove_user',
          method: "POST",
          body: {
            user_ids: userIds,
            room_id: roomId
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      addUser: builder.mutation<
        Result<any>, {
          roomId: string,
          userIds: string[]
        }
      >({
        query: ({ roomId, userIds }) => ({
          url: 'loc_chat/add_user',
          method: "POST",
          body: {
            user_ids: userIds,
            room_id: roomId
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      exitRoom: builder.mutation<
        Result<any>, {
          roomId: string
        }
      >({
        query: ({ roomId }) => ({
          url: 'loc_chat/exit_room',
          method: "POST",
          body: {
            room_id: roomId
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      applyHandler: builder.mutation<
        Result<any>, {
          applyId: string,
          action: string
        }
      >({
        query: ({ applyId, action }) => ({
          url: 'loc_chat/apply_handler',
          method: "POST",
          body: {
            apply_id: applyId,
            action
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getGroupChatTotalUnreadCount: builder.mutation<
        Result<GetGroupChatTotalUnreadCountResult>, {}>({
          query: () => ({
            url: 'loc_chat/get_group_chat_total_unread_count',
            method: "POST",
            body: {
            }
          }),
          transformResponse: (res: Response<GetGroupChatTotalUnreadCountResult>) => new Value(res),
        }),
      listPostByRoomId: builder.mutation<
        Result<ListPostByRoomIdResult>, {
          roomId: string,
          pageSize?: number,
          startKey?: string,
          forward?: boolean
        }>({
          query: ({ roomId, pageSize = 20, startKey = "", forward = false }) => ({
            url: 'loc_chat/list_post_by_room_id',
            method: "POST",
            body: {
              room_id: roomId,
              page_size: pageSize,
              start_key: startKey,
              forward
            }
          }),
          transformResponse: (res: Response<ListPostByRoomIdResult>) => new Value(res),
        }),
      getApplyList: builder.mutation<
        Result<GetApplyListResult>, {
          roomId: string,
          pageSize?: number,
          startKey?: string,
          forward?: boolean
        }>({
          query: ({ roomId, pageSize = 20, startKey = "", forward = false }) => ({
            url: 'loc_chat/get_apply_list',
            method: "POST",
            body: {
              room_id: roomId,
              page_size: pageSize,
              start_key: startKey,
              forward
            }
          }),
          transformResponse: (res: Response<GetApplyListResult>) => new Value(res),
        }),
      setChatroomSequence: builder.mutation<
          Result<undefined>, {
            room_id: string,
            sequence: number
          }>({
            query: ({ room_id, sequence }) => ({
              url: 'loc_chat/promote_room',
              method: "POST",
              body: {
                room_id,
                sequence
              }
            }),
            transformResponse: (res: Response<undefined>) => new Value(res),
          })
    };
  },
});

export interface GetPrivateRoomResult {
  room_info: PrivateRoom
}
export interface CheckUserInRoomResult {
  is_in: boolean;
}

export interface GetJoinedRoomResult {
  itemList: PrivateRoom[];
  start_key?: string;
}

export interface CreatePrivateChatroomResult {
  room_info: PrivateRoom;
}

export interface GetGroupChatTotalUnreadCountResult {
  total_unread_count: number;
}

export interface ListPostByRoomIdResult {
  itemList: PostEntity[];
  start_key?: string;
}

export interface GetApplyListResult {
  itemList: PrivateRoomApplyStatus[];
  start_key?: string;
}

export const {
  useGetRoomByNationMutation,
  useGetHistoryMessageByRoomIdMutation,
  useGetUsersByRoomIdMutation,
  useSearchRoomByTextMutation,
  useDeleteMessageMutation,
  useGetRoomByLocIdMutation,
  useFollowRoomMutation,
  useSetMessageReadMutation,
  useSetDialogMessageReadMutation,
  useGetFollowedRoomMutation,
  useUpdateRoomCoverMutation,
  useGetPostPictureMutation,
  useGetPostListByRoomMessageIdMutation,
  useGetContributorsByRoomIdMutation,
  useSearchMessageMutation,
  useGetRelevantMessageMutation,
  useUploadVisitorInfoMutation,
  useCheckUserInRoomMutation,
  useGetPrivateRoomMutation,
  useApplyJoinRoomMutation,
  useCheckApplyStatusMutation,
  useGetJoinedRoomMutation,
  useCreatePrivateChatroomMutation,
  useRemoveUserMutation,
  useAddUserMutation,
  useExitRoomMutation,
  useApplyHandlerMutation,
  useGetGroupChatTotalUnreadCountMutation,
  useListPostByRoomIdMutation,
  useGetApplyListMutation,
  useSetChatroomSequenceMutation
} = chatroomApi;
